import React, { useCallback, useEffect, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { ImmutableArray, ImmutableObject, State, useHookstate } from "@hookstate/core"
import { Validation } from "@hookstate/validation"
import { getActiveLanguage, useLanguageState } from "../../globalStates/LanguageState"
import {
    getDefaultContentLanguage,
    getElementFieldValue,
    getElementLocalizedName,
    makeSetHandler,
    makeValidationHandler,
    truncateWithEllipsis
} from "../../globalStates/utils"
import Section from "../../components/Section"
import SelectField from "../../components/Field/Select"
import PageContents from "../../components/PageContents"
import {
    Coupons,
    NotificationTemplate,
    NotificationTemplateTrigger,
    TargetGroupElement,
    TargetGroupQuestion
} from "../../globalStates/ExhibitorDataTypes"
import moment from "moment"
import "moment/locale/de"
import { convertHoursMinutesToDate, getHourFormat } from "../../utils/Dates"
import branding, { NotificationTemplateDate, NotificationTemplateTime } from "../../branding/branding"
import { ReferenceDataType, withReferenceDataState } from "../../globalStates/ReferenceDataHOC"
import TargetGroupElements from "./targetGroup/TargetGroupElements"
import BSForm from "react-bootstrap/Form"
import TextField from "../../components/Field/Text"
import { useExhibitorDataState } from "../../globalStates/ExhibitorDataState"
import LanguageSwitch from "../languageSwitch"
import {
    fieldNameByLang,
    getElementFieldValueWithLanguage,
    makeSetHandlerWithLanguage,
    useLanguageSwitch
} from "../../utils/Language"
import { Header } from "../../components/Field/ToggleSwitchField"
import { useSubelementPackagesState } from "../../globalStates/SubelementPackagesState"
import { TargetGroupFilter, useTargetGroupFiltersState } from "../../globalStates/TargetGroupFiltersState"
import ModalOk from "../../components/Modal/ModalOk"
import TextAreaField from "../../components/Field/TextArea"
import LinkCircle from "../../images/linkcircle.svg"

export function getInitialDate(notificationTemplateTrigger?: ImmutableObject<NotificationTemplateTrigger>): string | undefined {
    return branding.configuration.notificationTemplatesPage.sctNotificationTemplates.dates.find(
        (item: NotificationTemplateDate) => item.date === notificationTemplateTrigger?.timeFrameStart?.split("T")[0]
    )?.date
}

export function getInitialTime(notificationTemplateTrigger: ImmutableObject<NotificationTemplateTrigger>): string | undefined {
    return branding.configuration.notificationTemplatesPage.sctNotificationTemplates.times.find(
        (item: NotificationTemplateTime) =>
            item.start === notificationTemplateTrigger?.timeFrameStart?.split("T")[1]?.split(":00Z")[0]
    )?.id
}

interface NotificationTemplatesSectionProps {
    notificationTemplateState: State<NotificationTemplate, Validation>
    couponState: State<ImmutableObject<Coupons>, Validation>
    index: number
    onClickRemove: (notificationId: string, couponId?: string) => void
}

const NotificationTemplatesSection: React.FC<NotificationTemplatesSectionProps & ReferenceDataType> = ({
    notificationTemplateState,
    couponState,
    refDataState,
    index,
    onClickRemove
}) => {
    moment.locale(useLanguageState().getLanguage())

    const notificationTemplate = useHookstate(notificationTemplateState)
    const coupon = useHookstate(couponState)
    const sectionRef = useRef<HTMLDivElement>(null)

    const organization = useExhibitorDataState().getOrganization()

    const { currentLanguage, onChangeLanguageHandler } = useLanguageSwitch(getDefaultContentLanguage())

    const [date, setDate] = useState<string | null>(getInitialDate(notificationTemplateState.trigger.get()) || null)
    const [time, setTime] = useState<string | null>(getInitialTime(notificationTemplateState.trigger.get()) || null)
    const [destination, setDestination] = useState<string | null>(getInitialDestination() || null)

    const [blockEntryModalVisible, setBlockEntryModalVisible] = useState<boolean>(false)

    const subelementPackages = useSubelementPackagesState().getSubelementPackages()
    const targetGroupFilters = useTargetGroupFiltersState().getTargetGroupFilters()

    const standStates = useExhibitorDataState().getStands()

    const stands: { id: string; value: string; label: string }[] = standStates.map((item) => ({
        id: item.id.get(),
        value: item.id.get(),
        label: item.standName.get()
    }))

    const dates: { id: string; value: string; label: string }[] =
        branding.configuration.notificationTemplatesPage.sctNotificationTemplates.dates.map((item: NotificationTemplateDate) => ({
            id: item.id,
            value: item.date,
            label: `${moment(new Date(item.date)).format("dddd, DD.MM.yyyy")}`
        }))

    const times: { id: string; value: string; label: string }[] =
        branding.configuration.notificationTemplatesPage.sctNotificationTemplates.times.map((item: NotificationTemplateTime) => ({
            id: item.id,
            value: item.id,
            label:
                item.start === "00:00" && item.end === "23:59"
                    ? branding.configuration.notificationTemplatesPage.sctNotificationTemplates.lblEntireDay
                    : `${moment(convertHoursMinutesToDate(item.start)).format(getHourFormat())} - ${moment(
                          convertHoursMinutesToDate(item.end)
                      ).format(getHourFormat())}`
        }))

    function clearInterests() {
        let targetGroup = JSON.parse(JSON.stringify(notificationTemplate.targetGroup.get()))

        const subelementPackage = subelementPackages.find((sp) => sp.id === notificationTemplateState.subelementPackage.get())

        const criteriaLimit = subelementPackage?.subelementContingents["notificationTemplate.criteria"] || 0

        if (criteriaLimit === 0) {
            targetGroup.interests = undefined
            notificationTemplate.targetGroup.set({})
        }
    }

    function getInitialDestination(): string | undefined {
        const id = notificationTemplateState.destinations.get()[0]?.id
        return id?.split("#")[1] ?? id
    }

    function getCriteriaListCount() {
        let selected = 0

        if ((notificationTemplateState.targetGroup.get()?.interests || []).length > 0) {
            selected += 1
        }

        if ((notificationTemplateState.targetGroup.get()?.countriesFilter?.values || []).length > 0) {
            selected += 1
        }

        selected += (notificationTemplateState.targetGroup.get()?.questionFilters || []).length

        return selected
    }

    function generateCriteriaListCounter() {
        const subelementPackage = subelementPackages.find((sp) => sp.id === notificationTemplateState.subelementPackage.get())

        return branding.configuration.notificationTemplatesPage.sctNotificationTemplates.msgCriteriaList
            .replace("{$selected}", getCriteriaListCount().toString())
            .replace(
                "{$total}",
                subelementPackage ? subelementPackage.subelementContingents["notificationTemplate.criteria"]?.toString() : "0"
            )
    }

    function getTargetGroupFilterElements(
        targetGroupFilter: ImmutableObject<TargetGroupFilter>
    ): ImmutableArray<TargetGroupElement> {
        switch (targetGroupFilter.type) {
            case "INTEREST":
                return (notificationTemplate.targetGroup.get().interests || []).map((interest) => {
                    return {
                        id: interest.id,
                        credits: interest.credits
                    }
                })
            case "COUNTRY":
                return (notificationTemplateState.targetGroup.get().countriesFilter?.values || []).map((value: string) => {
                    return { id: value }
                })
            case "QUESTION": {
                let questionFilters: ImmutableArray<TargetGroupQuestion> =
                    notificationTemplateState.targetGroup.get().questionFilters || []

                return (
                    Object.values(questionFilters).find((question) => question.questionId === targetGroupFilter.id)?.answerIds ||
                    []
                ).map((value: string) => {
                    return { id: value }
                })
            }
            default:
                return []
        }
    }

    const onClickRemoveHandler = useCallback(() => {
        onClickRemove(notificationTemplate.id.get(), couponState ? couponState.id.get() : undefined)
    }, [onClickRemove, notificationTemplate, couponState])

    useEffect(() => {
        clearInterests()
        // eslint-disable-next-line
    }, [notificationTemplate])

    useEffect(() => {
        const timeObject = branding.configuration.notificationTemplatesPage.sctNotificationTemplates.times.find(
            (item: NotificationTemplateTime) => item.id === time
        )

        let trigger = JSON.parse(JSON.stringify(notificationTemplate.trigger.get()))

        if (date && timeObject) {
            trigger!["timeFrameStart"] = `${date}T${timeObject.start}:00.00Z`
            trigger!["timeFrameEnd"] = `${date}T${timeObject.end}:00.00Z`
        } else {
            trigger!["timeFrameStart"] = ""
            trigger!["timeFrameEnd"] = ""
        }

        notificationTemplate.date.set(date || "")
        notificationTemplate.trigger.set(trigger)

        //eslint-disable-next-line
    }, [date, time])

    useEffect(() => {
        if (destination) {
            let stand = standStates.find((item) => item.id.get() === destination)
            let trigger = JSON.parse(JSON.stringify(notificationTemplate.trigger.get()))

            trigger!["geofences"] = [{ id: `hall#${destination.split("__")[0]}` }]

            notificationTemplate.trigger.set(trigger)

            notificationTemplate.destinations.set([{ id: `stand#${destination}` }])

            notificationTemplate.text.set(
                branding.configuration.notificationTemplatesPage.sctNotificationTemplates.tmpText
                    .replace("{$organization}", organization.name.get())
                    .replace("{$destination}", stand?.standName?.get()!)
            )

            notificationTemplate.localized.set({
                de: {
                    text: branding.configuration.notificationTemplatesPage.sctNotificationTemplates.tmpTextDe
                        .replace("{$organization}", organization.name.get())
                        .replace("{$destination}", stand?.standNameDe?.get()!)
                }
            })
        } else {
            let trigger = JSON.parse(JSON.stringify(notificationTemplate.trigger.get()))

            trigger!["geofences"] = []

            notificationTemplate.trigger.set(trigger)
            notificationTemplate.destinations.set([])
        }

        // eslint-disable-next-line
    }, [destination])

    return (
        <Section
            ref={sectionRef}
            title={
                notificationTemplate.text.get()
                    ? truncateWithEllipsis(notificationTemplate.text.get(), 200)
                    : branding.configuration.notificationTemplatesPage.sctNotificationTemplates.titleEmptyNotificationTemplate
            }
            collapsible
            onClickRemove={onClickRemoveHandler}
            entityState={notificationTemplate}
        >
            <Row>
                <Col xs={12}>
                    <Row>
                        <Col>
                            <LanguageSwitch
                                id={`notificationTemplate_${index}_language_switch`}
                                name={`notificationTemplate_${index}_languages`}
                                value={currentLanguage}
                                onChange={onChangeLanguageHandler}
                                entityState={notificationTemplateState}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5 style={{ marginBottom: "20px", paddingBottom: "5px", borderBottom: "1px solid #d9d9d9" }}>
                                {
                                    branding.configuration.notificationTemplatesPage.sctNotificationTemplates
                                        .titleEmptyNotificationTemplate
                                }
                            </h5>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <SelectField
                                name="destination"
                                label={branding.configuration.notificationTemplatesPage.sctNotificationTemplates.lblDestination}
                                placeholder={
                                    branding.configuration.notificationTemplatesPage.sctNotificationTemplates.hntDestination
                                }
                                options={stands}
                                value={destination}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDestination(e.target.value)}
                                validate={makeValidationHandler(notificationTemplateState, "destinations")}
                                required
                            />
                        </Col>
                        <Col>
                            <TextField
                                name="maxUsageCount"
                                label={branding.configuration.notificationTemplatesPage.sctNotificationTemplates.lblMaxUsageCount}
                                value={getElementFieldValue(notificationTemplate, "maxUsageCount")}
                                onChange={makeSetHandler(notificationTemplate, "maxUsageCount")}
                                readonly
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextField
                                id="text"
                                name={getElementLocalizedName(notificationTemplate.get(), currentLanguage.toLowerCase(), "text")}
                                label={branding.configuration.notificationTemplatesPage.sctNotificationTemplates.lblText}
                                placeholder={branding.configuration.notificationTemplatesPage.sctNotificationTemplates.hntText}
                                value={getElementLocalizedName(notificationTemplate.get(), currentLanguage.toLowerCase(), "text")}
                                validate={makeValidationHandler(notificationTemplateState, "text")}
                                onChange={makeSetHandlerWithLanguage(notificationTemplate, "text", currentLanguage)}
                                limit={400}
                                readonly
                                required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SelectField
                                name="date"
                                label={branding.configuration.notificationTemplatesPage.sctNotificationTemplates.lblDate}
                                placeholder={branding.configuration.notificationTemplatesPage.sctNotificationTemplates.hntDate}
                                options={dates}
                                value={date}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let value = e.target.value
                                    setDate(!!value ? value : "")

                                    if (!value) {
                                        setTime("")
                                    }
                                }}
                            />
                        </Col>
                        <Col>
                            <SelectField
                                name="time"
                                label={branding.configuration.notificationTemplatesPage.sctNotificationTemplates.lblTime}
                                placeholder={branding.configuration.notificationTemplatesPage.sctNotificationTemplates.hntTime}
                                options={times}
                                value={time}
                                infoText={
                                    !date && branding.configuration.notificationTemplatesPage.sctNotificationTemplates.infoTime
                                }
                                readonly={!date}
                                required={!!date}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    let value = e.target.value
                                    setTime(!!value ? value : "")
                                }}
                                validate={makeValidationHandler(notificationTemplateState, "trigger")}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <BSForm.Group>
                                <Header>
                                    <BSForm.Label style={{ marginTop: "20px", marginBottom: "-20px" }}>
                                        {
                                            branding.configuration.notificationTemplatesPage.sctNotificationTemplates
                                                .lblCriteriaList
                                        }
                                    </BSForm.Label>
                                    <BSForm.Label style={{ marginTop: "20px", marginBottom: "-20px" }}>
                                        {generateCriteriaListCounter()}
                                    </BSForm.Label>
                                </Header>
                            </BSForm.Group>

                            <PageContents
                                description={
                                    branding.configuration.notificationTemplatesPage.sctNotificationTemplates.hntCriteriaList
                                }
                            >
                                {targetGroupFilters.map((targetGroupFilter) => {
                                    return (
                                        <TargetGroupElements
                                            id={targetGroupFilter.id}
                                            name={getElementLocalizedName(targetGroupFilter, getActiveLanguage(), "name")}
                                            selectedElements={Array.from(getTargetGroupFilterElements(targetGroupFilter))}
                                            values={targetGroupFilter.values}
                                            addElement={(id: string) => {
                                                let targetGroup = JSON.parse(
                                                    JSON.stringify(notificationTemplate.targetGroup.get())
                                                )

                                                const subelementPackage = subelementPackages.find(
                                                    (sp) => sp.id === notificationTemplateState.subelementPackage.get()
                                                )

                                                if (
                                                    getTargetGroupFilterElements(targetGroupFilter).length === 0 &&
                                                    subelementPackage &&
                                                    getCriteriaListCount() ===
                                                        (subelementPackage?.subelementContingents[
                                                            "notificationTemplate.criteria"
                                                        ] || 0)
                                                ) {
                                                    setBlockEntryModalVisible(true)
                                                    return
                                                }

                                                let elements

                                                switch (targetGroupFilter.type) {
                                                    case "INTEREST": {
                                                        elements = [
                                                            ...(notificationTemplate.targetGroup.get().interests || []).map(
                                                                (interest) => {
                                                                    return {
                                                                        id: interest.id,
                                                                        credits: interest.credits
                                                                    }
                                                                }
                                                            ),
                                                            { id: id, credits: "1" }
                                                        ]

                                                        targetGroup.interests = elements

                                                        break
                                                    }
                                                    case "COUNTRY": {
                                                        let values =
                                                            notificationTemplate.targetGroup.get().countriesFilter?.values || []
                                                        elements = {
                                                            type: "include",
                                                            values: [...values, id]
                                                        }

                                                        targetGroup.countriesFilter = elements
                                                        break
                                                    }
                                                    case "QUESTION": {
                                                        let questionFilters = Object.values(
                                                            notificationTemplateState.targetGroup.get().questionFilters || []
                                                        )

                                                        let nonMatchingQuestions = questionFilters
                                                            .filter((question) => question.questionId !== targetGroupFilter.id)
                                                            .map((question) => {
                                                                return {
                                                                    questionId: question.questionId,
                                                                    answerIds: question.answerIds.map((answerId) => {
                                                                        return answerId
                                                                    })
                                                                }
                                                            })

                                                        let answerIds =
                                                            Object.values(questionFilters).find(
                                                                (question: ImmutableObject<TargetGroupQuestion>) =>
                                                                    question.questionId === targetGroupFilter.id
                                                            )?.answerIds || []

                                                        let newQuestionElement = {
                                                            questionId: targetGroupFilter.id,
                                                            answerIds: [...answerIds, id]
                                                        }

                                                        elements = [...nonMatchingQuestions, newQuestionElement]

                                                        targetGroup.questionFilters = elements

                                                        break
                                                    }
                                                    default:
                                                        break
                                                }

                                                notificationTemplate.targetGroup.set(targetGroup)
                                            }}
                                            removeElement={(id: string) => {
                                                let targetGroup = JSON.parse(
                                                    JSON.stringify(notificationTemplate.targetGroup.get())
                                                )

                                                let elements

                                                switch (targetGroupFilter.type) {
                                                    case "INTEREST": {
                                                        elements = (notificationTemplate.targetGroup.get().interests || [])
                                                            .map((interest) => {
                                                                return {
                                                                    id: interest.id,
                                                                    credits: interest.credits
                                                                }
                                                            })
                                                            .filter((element: TargetGroupElement) => element.id !== id)

                                                        targetGroup.interests = elements

                                                        break
                                                    }
                                                    case "COUNTRY": {
                                                        let values = Object.values(
                                                            notificationTemplate.targetGroup.get().countriesFilter?.values || []
                                                        ).filter((value) => value !== id)

                                                        elements = {
                                                            type: "include",
                                                            values: values
                                                        }

                                                        targetGroup.countriesFilter = elements
                                                        break
                                                    }
                                                    case "QUESTION": {
                                                        let questionFilters = Object.values(
                                                            notificationTemplateState.targetGroup.get().questionFilters || []
                                                        )

                                                        let nonMatchingQuestions = questionFilters
                                                            .filter((question) => question.questionId !== targetGroupFilter.id)
                                                            .map((question) => {
                                                                return {
                                                                    questionId: question.questionId,
                                                                    answerIds: question.answerIds.map((answerId) => {
                                                                        return answerId
                                                                    })
                                                                }
                                                            })

                                                        let answerIds = (
                                                            Object.values(questionFilters).find(
                                                                (question: ImmutableObject<TargetGroupQuestion>) =>
                                                                    question.questionId === targetGroupFilter.id
                                                            )?.answerIds || []
                                                        ).filter((element: string) => element !== id)

                                                        let newQuestionElement = {
                                                            questionId: targetGroupFilter.id,
                                                            answerIds: answerIds
                                                        }

                                                        if (newQuestionElement.answerIds.length > 0) {
                                                            elements = [...nonMatchingQuestions, newQuestionElement]
                                                        } else {
                                                            elements = nonMatchingQuestions
                                                        }

                                                        targetGroup.questionFilters = elements

                                                        break
                                                    }
                                                    default:
                                                        break
                                                }

                                                notificationTemplate.targetGroup.set(targetGroup)
                                            }}
                                        />
                                    )
                                })}
                            </PageContents>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {couponState && coupon && (
                <Row>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "30px",
                            marginBottom: "40px",
                            width: "100%"
                        }}
                    >
                        <div
                            style={{
                                borderTop: "1px solid #d9d9d9",
                                flex: 2,
                                marginTop: "20px",
                                marginRight: "15px",
                                marginLeft: "-10px"
                            }}
                        />
                        <div
                            style={{
                                border: "1px solid #d9d9d9",
                                borderRadius: "50%",
                                width: "45px",
                                height: "45px",
                                padding: "12px 12px 20px 12px"
                            }}
                        >
                            <img src={LinkCircle} alt="icon" />
                        </div>
                        <div
                            style={{
                                borderTop: "1px solid #d9d9d9",
                                flex: 2,
                                marginTop: "20px",
                                marginLeft: "15px",
                                marginRight: "-10px"
                            }}
                        />
                    </div>

                    <Col xs={12}>
                        <Row>
                            <Col>
                                <h5 style={{ marginBottom: "20px", paddingBottom: "5px", borderBottom: "1px solid #d9d9d9" }}>
                                    {branding.configuration.notificationTemplatesPage.sctNotificationTemplates.titleEmptyCoupon}
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextField
                                    name="name"
                                    label={
                                        branding.configuration.notificationTemplatesPage.sctNotificationTemplates.lblCouponsName
                                    }
                                    placeholder={
                                        branding.configuration.notificationTemplatesPage.sctNotificationTemplates.hntCouponsName
                                    }
                                    value={getElementFieldValue(coupon, "name")}
                                    onChange={makeSetHandler(coupon, "name")}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextAreaField
                                    name={fieldNameByLang("descriptionTitle", currentLanguage)}
                                    label={
                                        branding.configuration.notificationTemplatesPage.sctNotificationTemplates
                                            .lblDescriptionTitle
                                    }
                                    placeholder={
                                        branding.configuration.notificationTemplatesPage.sctNotificationTemplates
                                            .hntDescriptionTitle
                                    }
                                    value={getElementFieldValueWithLanguage(coupon, "descriptionTitle", currentLanguage)}
                                    onChange={makeSetHandlerWithLanguage(coupon, "descriptionTitle", currentLanguage)}
                                    limit={200}
                                    style={{ height: "80px" }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <TextAreaField
                                    name={fieldNameByLang("descriptionLong", currentLanguage)}
                                    label={
                                        branding.configuration.notificationTemplatesPage.sctNotificationTemplates
                                            .lblDescriptionLong
                                    }
                                    placeholder={
                                        branding.configuration.notificationTemplatesPage.sctNotificationTemplates
                                            .hntDescriptionLong
                                    }
                                    value={getElementFieldValueWithLanguage(coupon, "descriptionLong", currentLanguage)}
                                    onChange={makeSetHandlerWithLanguage(coupon, "descriptionLong", currentLanguage)}
                                    limit={600}
                                    style={{ height: "80px" }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}

            <ModalOk
                name="blockEntry"
                title={branding.configuration.notificationTemplatesPage.sctNotificationTemplates.lblBlockTriggerModalTitle}
                visible={blockEntryModalVisible}
                onOk={() => {
                    setBlockEntryModalVisible(false)
                }}
                labelOk={branding.configuration.actionbar.modalDiscardOK}
            >
                {branding.configuration.notificationTemplatesPage.sctNotificationTemplates.lblBlockTriggerModalDescription}
            </ModalOk>
        </Section>
    )
}

export default React.memo(withReferenceDataState(NotificationTemplatesSection))
