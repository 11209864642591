import React, { PropsWithChildren, useCallback, useState } from "react"
import styled from "styled-components"
import { State } from "@hookstate/core"
import { Validation } from "@hookstate/validation"
import { hasErrorsAll } from "../../globalStates/utils"
import ErrorBoundary from "../ErrorBoundary"
import { RemoveTool } from "./SectionTools"

const Wrapper = styled.div`
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 30px;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    background-color: #d9d9d9;
    font-weight: bold;
    cursor: pointer;
`

const Title = styled.div`
    color: #444650;
    &.invalid {
        color: #dc3545;
    }
`

const ActionBar = styled.div``

interface ContentProps {
    visible: boolean
}

//TODO add animation/transition
const Content = styled.div<ContentProps>`
    display: ${(props) => (props.visible ? "block" : "none")};
    // height:  ${(props) => (props.visible ? "auto" : "0px")};
    // transition: height 15s ease-out;
    padding: 20px 25px 5px 25px;
`

interface SectionProps {
    title: string
    collapsible?: boolean
    opacity?: number
    onClickTitle?: () => void
    onClickRemove?: () => void
    entityState?: State<any, Validation>
}

const Section = React.forwardRef<HTMLDivElement, PropsWithChildren<SectionProps>>(
    ({ title, collapsible = false, opacity = 1, onClickTitle, onClickRemove, entityState, children }, ref) => {
        const isInvalid = hasErrorsAll(entityState)
        const [open, setOpen] = useState(!collapsible)
        const onClickHandler = useCallback(
            (event: React.MouseEvent<HTMLDivElement>) => {
                event.preventDefault()
                event.stopPropagation()
                if (collapsible) setOpen(!open)
                onClickTitle && onClickTitle()
            },
            [collapsible, onClickTitle, open]
        )
        return (
            <Wrapper style={{ opacity }}>
                <Header ref={ref} onClick={onClickHandler}>
                    <Title className={isInvalid ? "invalid" : ""}>{title}</Title>
                    <ActionBar>{onClickRemove && <RemoveTool onClick={onClickRemove} />}</ActionBar>
                </Header>
                {open && (
                    <ErrorBoundary>
                        <Content visible={open}>{children}</Content>
                    </ErrorBoundary>
                )}
            </Wrapper>
        )
    }
)

export default Section
